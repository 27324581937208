
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import StockPhotos from '@/components/toolkit/details/row/custom/StockPhotos.vue'
  import { Deal } from '@/entities/crm'
  import { fixPrice, isValidNumber } from '@/utils/general'
  import { GForm } from '@/components/forms/GForm'
  import { Inspection } from '@/entities/purchase'
  import { plainToInstance } from 'class-transformer'
  import { Stock } from '@/entities/sales'
  import { Auto } from '@/entities/public'

@Component({
  methods: { isValidNumber },
  components: { StockPhotos },
})
  export default class AutoResume extends GForm {
  @Prop({ type: Deal, default: null }) deal!: Deal
  @Prop({ type: Stock, default: null }) stock!: Stock
  @Prop({ type: Boolean, default: false }) notDisplayAppraisal!: boolean
  @Prop({ type: Boolean, default: false }) notDisplayVersion!: boolean
  @Prop({ type: Boolean, default: false }) displayCarousel!: boolean
  @Prop({ type: Boolean, default: true }) hiddenBottom!: boolean
  km = null
  owner = null

  autoStock = null
  stockAttributes: [] = []
  alternativeAttributes: [] = []
  inspection: Inspection = plainToInstance(Inspection, {})

  // Methods
  async findAlternativesAttributes (auto) {
    if (!auto?.version?.version?.id || !auto?.version?.year?.id) return

    let getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id)

    let generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.id === auto.generation?.id)

    if (generation?.attributes?.length) {
      return generation?.attributes
    }

    const attributes = await this.fetchData({
      query: {
        name: 'find',
        model: 'Attribute',
      },
      filter: {
        id_version_year: { _eq: auto.version.id },
      },
    })

    if (attributes?.length) {
      return attributes
    }

    if (!auto?.generation?.sku) {
      return
    }

    let cont = 1
    while (cont <= 3 && !generation?.attributes?.length) {
      getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id - cont)

      generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.sku === auto.generation?.sku)

      if (generation?.attributes?.length) {
        return generation?.attributes || []
      } else {
        cont++
      }
    }

    return generation?.attributes || []
  }

  // Getters
  get auto () {
    const { deal, autoStock } = this

    if (!deal) {
      return autoStock || null
    }

    return deal?.auto
  }

  get traction () {
    const { alternativeAttributes } = this

    return alternativeAttributes?.find(att => att?.component?.slug === 'traction') || []
  }

  get fuel () {
    const { alternativeAttributes } = this

    return alternativeAttributes?.find(att => att.component?.slug === 'fuel') || []
  }

  get transmission () {
    const { alternativeAttributes } = this

    return alternativeAttributes?.find(att => att.component?.slug === 'transmission') || []
  }

  get mileage () {
    const { deal, km, stockAttributes } = this

    const attributes = deal?.autoAttributes || stockAttributes

    const att = km || attributes?.sort((a, b) => b.id - a.id)?.find(att => att.slug === 'mileage')
    return att || '-'
  }

  get owners () {
    const { deal, owner, stockAttributes } = this
    const attributes = deal?.autoAttributes || stockAttributes
    const att = owner || attributes?.find(att => att.slug === 'owners_number')

    return att || '-'
  }

  get maintenance () {
    const { auto } = this

    return auto.maintenances.find(maintenance => maintenance.status.isDone)
  }

  get components () {
    const { fuel, transmission, traction, mileage, owners } = this

    return [
      {
        label: 'Kilometraje',
        value: isValidNumber(mileage?.val) ? fixPrice(mileage?.val, false) : '-',
        icon: mileage?.component?.icon?.main,
      },
      { label: 'Combustible', value: fuel?.val, icon: fuel?.component?.icon?.main },
      {
        label: 'Transmisión',
        value: transmission?.val ? transmission.val : '-',
        icon: transmission?.component?.icon?.main,
      },
      { label: 'Tracción', value: traction?.val ? traction.val : '-', icon: traction?.component?.icon?.main },
      { label: 'Nº de dueños', value: owners?.val ? owners.val : '-', icon: owners?.component?.icon?.main },
    ]
  }

  // Watchers
  @Watch('deal', { immediate: true, deep: true })
  async onAutoChange (val: Deal) {
    if (!val?.id) return

    if (val?.auto && !this.alternativeAttributes?.length) {
      this.alternativeAttributes = await this.findAlternativesAttributes(val.auto)
    }

    if (!val?.autoAttributes?.length && !this.stock?.id) {
      this.deal.autoAttributes = await this.fetchData({
        query: { name: 'find', model: 'DealAutoAttribute' },
        filter: { id_deal: { _eq: val.id } },
        force: true,
      })
    }

    if (!val?.appraisal?.id && !this.stock?.id) {
      this.deal.appraisals = (await this.fetchData({
        query: { name: 'find', model: 'Appraisal' },
        filter: { id_deal: { _eq: val.id } },
        force: true,
      }))
    }

    if (!this.inspection?.id && val?.id && !this.stock?.id) {
      this.inspection = (await this.fetchData({
        query: { name: 'findLite', model: 'Inspection', order: { id: 'desc' } },
        filter: { appraisal: { id_deal: { _eq: val.id } } },
        force: true,
      }))[0]
    }

    if (this.stock?.viewDetails?.idInspection && !this.inspection?.id) {
      this.inspection = plainToInstance(Inspection, { id: this.stock?.viewDetails?.idInspection })
    }

    await this.getAutoAttribute(val?.auto)

    this.$emit('auto-loaded')
  }

  get allowDisplay () {
    const { inspection, stock, appraisal } = this

    return inspection?.id || stock?.id || appraisal?.id
  }

  get appraisal () {
    const { deal } = this

    return deal?.appraisal
  }

  async getAutoAttribute (value) {
    if (value instanceof Auto) {
      const { id } = value

      if (!id) return

      const filter = {
        _and: [
          { id_auto: { _eq: id } },
          { component: { slug: { _in: ['mileage', 'owners_number'] } } },
        ],
      }
      const attributes = await this.fetchData({
        query: { name: 'find', model: 'AttributeView', order: { id_attribute: 'desc' } },
        filter,
        force: true,
      })

      this.km = attributes?.sort((a, b) => b.id - a.id)?.find(attr => attr.slug === 'mileage')
      this.owner = attributes?.find(attr => attr.slug === 'owners_number')
    }
  }

  @Watch('stock', { immediate: true, deep: true })
  async onStockChange (val: Stock) {
    if (!val?.id) return

    const viewDetails = (await this.fetchData({
      query: { name: 'find', model: 'StockViewDetails' },
      filter: { id_stock: { _eq: val.id } },
      force: true,
    }))[0]

    this.stockAttributes = viewDetails?.attributes || []

    this.autoStock = viewDetails?.auto
    this.alternativeAttributes = await this.findAlternativesAttributes(viewDetails?.auto)
  }
  }
